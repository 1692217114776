/* Split panel */

.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  /* width: 2px; */
  margin: 0 -5px;
  box-shadow: 0px 4px 4px #00000025;
  /* border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0); */
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}

.Pane1 {
  box-shadow: 0px 10px 11px #00000025 !important;
}

/* .SplitPane{
  min-height: 60vh !important;
  height: 60vh;
} */

/* End */

.selected {
  color: #00afb9 !important;
  background: #f7f7f7 !important;
  display: block;
  /* padding:1em 0; */
}

.sideNav menu {
  padding: 1em 0;
}

/* .sideNav menu>div{
  padding:1em 0;
} */

.menuImg {
  width: 64px;
  vertical-align: middle;
}

.menuAdj {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 80%;
  padding: 1em 0;
  cursor: pointer;
}

.menuAdj > div:nth-child(1) {
  width: 30%;
}

.menuAdj > div:nth-child(2) {
  width: 70%;
}

.menuAdj > div:nth-child(2) {
  text-align: left;
  margin-left: 1.5em;
  font-size: 18px;
}

.sideNav menu > div a,
.sideNav menu > div a:active,
.sideNav menu > div a:visited {
  color: #000000;
  text-decoration: none;
}

.paperModal {
  position: absolute;
  width: 60%;
  background-color: #fff;
  border-radius: 20px;
  /* overflow: scroll !important; */
  outline: none;
}
