.container{
    padding: 1em 2em;
}

header h1{
    font-size: 22px;
    font-weight: 500;
}

.loginform{
    padding-top: 2em;
    padding-bottom: 2em;
}
.NextBtn {
    background: #f07167;
    border: none;
    outline: none;
    padding: 12px;
    width: 20%;
    color: white;
    border-radius: 10px;
    margin-top: 50px;
    margin-bottom: 40px;
    font-size: 15px;
    cursor: pointer;
    outline: none;
  }
  
.labelStyle{
    color: #02afb9;
}
.MuiFormControl-root
    {
    padding-top: 11px !important;
}

.textDate{
    font-size: 16px;
    padding: 10px;
    outline: none;
    padding-top: 30px;
    width: 91%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #02afb9;
    text-transform: uppercase;
}
.textInput
{
    font-size: 16px;
    padding: 10px;
    outline: none;
    width: 91%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #02afb9;
}
.btnDiv{
    padding-top: 2em;
    padding-bottom: 2em;
}
.centerAdj{
    display: flex;
    justify-content: center;
}
.subBtn{
    background: #F07167;
    border: none;
    outline: none;
    padding: 12px;
    width: 27%;
    color: white;
    border-radius: 10px;
    margin-top: 55px;
    font-size: 16px;
    cursor: pointer;
}

/* Mobile */
@media only screen and (max-width: 600px) {
    .inputDiv {
      width: 80% !important;
    }
    .loginBG
    {
        background-image:none !important;
        background-size: cover; 
    }
    .mobformat
{
    margin-left: 25% !important;
}
.footeerlogin
{
    margin-top: 25px !important;
}
.welcome h3 {
    font-size: 27px !important;
}
.welcome h4 {
    font-size: 30px !important;
}
  }
  /* Tab */
  
@media only screen and (min-width:601px) and (max-width:768px){

    .mobformat
    {
        margin-left: 25% !important;
    }
    .inputDiv{
        width: 50% !important
    }
  }