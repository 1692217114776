.container{
    padding: 1em 2em;
}

.tableForm{
    padding-top: 2em;
    padding-bottom: 2em;
}




.modalBox{
  
 
    position: fixed; /* Stay in place */
    z-index: 1111111 !important; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  .modal-content{
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 45%; /* Could be more or less, depending on screen size */
    border-radius:20px;
  
    position: relative;
  }
  
  .closeIcn{
    position:absolute;
    right: 5px;
    width: 24px;
    cursor: pointer;
  margin-top: -15px;

  }
  
  .btnAdjHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: 2em 0; */
  }
  .btnAdjHeader>button:nth-child(1) {
    margin-right:1em;
    /* margin: 2em 0; */
  }
  
  .subBtn10 {
    background: #f07167;
    border: none;
    outline: none;
    padding: 12px;
    width: 20%;
    color: white;
    border-radius: 10px;
    margin-top: 24px;
    font-size: 15px;
    cursor: pointer;
  }
  
  .logoheader{
    display: flex;
  }
  
  .modal-content>div:nth-child(1){
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  
  .header {
      height: 30px;
      overflow: hidden;
      background-color: #fff;
      box-shadow:  0px 4px 4px #00000025;
      padding: 20px 10px;
      border-radius: 0px 0px 26px 26px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .logoHeader
    {
      width: 229px;
      float: left;
      margin-left: 10px;
      margin-top: -1px;
    }
  
    .logoutImg{
      margin-right: 19px;
      margin-left: 20px;
      margin-top: -8px;
      cursor: pointer;
    }
  
    .floatRighter{
      display: flex;
    }
  
    .floatRighter span{
      color: #F07167;
      font-size:18px;
    }
  
    .facilityName{
      color: #00AFB9;
      font-size: 22px;
    }
    .linkBack
    {
        margin-right: 40px;
        color: #F07167;
        cursor: pointer;
        margin-top: 40px;
    }